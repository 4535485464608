/**
 * Copyright 2023 LiveKit, Inc.
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

body {
  padding: 0;
  font-family: Avenir, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Oxygen', 'Ubuntu',
    'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: #0d1126 ;
  color: rgb(211, 210, 210);
  box-sizing: border-box;
  margin: 0;
  height: 100vh;
  font-size: 12px;
  overflow: hidden;
}
.lk-participant-metadata {
  position: absolute !important;
  right: 0.25rem !important;
  bottom: 0.25rem !important;
  left: 0.25rem !important;
  display: flex !important;
  flex-direction: row !important;
  align-items: center !important;
  justify-content: space-between !important;
  gap: 0.5rem !important;
  line-height: 1 !important;
}
.lk-participant-metadata-item {
 
  border-radius: 0.5rem !important;
}
.light {
  background: white;
}
.lk-carousel{
	background-color: #292929 !important;
  gap: 0.5rem;
  overflow: hidden !important;
	}
.roomContainer {
  height: 100vh;
}

.error {
  color: red;
}

.lk-participant-placeholder {
 
  background-color: #1e1e1e !important;
  opacity: 0;
  transition: opacity .2s ease-in-out;
  pointer-events: none;
  border-radius:0.5rem !important;
}

.lk-grid-layout{
  gap:0.5rem !important
}
.lk-participant-media-video{
	aspect-ratio: 16/9 !important;
	height: 100% !important;
	object-fit: contain !important;
	background-color: rgba(55, 55, 55, 0.3) !important;
  border-radius: 10px !important;

	
} 

 
.lk-grid-layout .lk-participant-tile {
  aspect-ratio: 16/9 !important;
	height: 100% !important;
	width:100% !important;
}
.single-layout{
 height: 100% !important;
}
.lk-focus-layout {
  height: 100%;
  gap:0.5rem ;
}

/* things like name, connection quality, etc make less sense in a recording, hide for now */
.lk-participant-metadata {
  display: none;
}
.PresenterMode {
  position: absolute !important;
border: 1px solid rgba(255, 255, 255, 0.699) !important;
  width: 18%;
  max-width: 20%;
  max-height: 30%;
  bottom: 0;
  left: 81%;
  object-fit: contain;
	display: flex;
	justify-content: center;
	background-color: #1E1E1E;
  border-radius: 10px;
}
.PlaceholderPresenter{
	width: 50%;
	height: 50%;
}
.Presenter-screenshare {
  border: 1px solid white !important;
}
.participantNamePlaceholder{
	font-family: 'Roboto', sans-serif;
	font-size: 1.6vw;
	font-weight: 600;
	position: absolute;
	display: flex;
	justify-content: center;
	align-items: center;
	height: 100%;
	width: 100%;
}
@keyframes swipeInFromRight {
	0% {
	  transform: translateX(100%) scale(0.5);
	  opacity: 0;
	}
	100% {
	  transform: translateX(0) scale(1);
	  opacity: 1;
	}
  }
  
  .ImageUrlPresenter {
	animation: swipeInFromRight 1s ease-in-out forwards; 
  }
  
